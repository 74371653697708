.g-container {
  height: 100%;
  color: #606266;
  position: relative;
  background-color: #fff;
}
.g-container .g-first {
  height: 100%;
  padding: 0 10px;
  position: relative;
}
.g-container .g-second {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: 10;
  padding: 0 10px;
}
.g-search-wrap {
  padding: 10px 150px 0 0;
  position: relative;
}
.g-search-wrap .g-search-btn {
  position: absolute;
  right: 0;
  top: 15px;
}
.g-title {
  position: relative;
  padding: 15px 0 15px 13px;
  line-height: 1;
  font-size: 18px;
  font-weight: bold;
  color: #303133;
}
.g-title:before {
  content: '';
  position: absolute;
  top: 17px;
  height: 18px;
  width: 4px;
  background-color: #1b65b9;
  left: 0;
}
.g-desc-wrap {
  font-size: 0;
  border: 1px solid #cecece;
  border-radius: 2px;
  padding: 10px 15px;
}
.g-desc-wrap .g-desc-item {
  display: inline-block;
  font-size: 14px;
  margin-right: 20px;
}
.g-desc-wrap .g-desc-item:last-child {
  margin-right: 0;
}
.g-desc-wrap .g-desc-item label {
  font-weight: bold;
}
.g-desc-wrap .g-desc-item span {
  margin-left: 5px;
  color: #1b65b9;
}
.g-table-wrap {
  position: absolute;
  left: 10px;
  right: 10px;
  bottom: 44px;
}
.g-tabel-btn {
  font-size: 14px;
  color: #1b65b9;
  cursor: pointer;
  margin-right: 5px;
}
.g-tabel-btn:hover {
  color: #4581c6;
}
.g-tabel-btn:last-child {
  margin-right: 0;
}
.g-border-wrap {
  border: 1px solid #cecece;
  padding: 10px;
  border-radius: 2px;
  position: absolute;
  left: 10px;
  right: 10px;
  bottom: 44px;
}
.g-border-wrap .g-border-title {
  font-size: 16px;
  font-weight: bold;
  color: #303133;
}
.g-border-wrap .g-border-table-wrap {
  position: absolute;
  left: 10px;
  right: 10px;
  bottom: 50px;
}
.g-border-wrap .g-border-btn-wrap {
  position: absolute;
  left: 10px;
  right: 10px;
  bottom: 10px;
}
.g-page-wrap {
  padding: 10px 0;
  position: absolute;
  bottom: 0;
  left: 10px;
  right: 10px;
}
.g-detail-table {
  width: 100%;
  border-collapse: collapse;
}
.g-detail-table tr:nth-child(odd) td {
  background-color: #f0f2f5;
}
.g-detail-table tr td {
  padding: 10px 15px 10px 5px;
  vertical-align: top;
}
.g-detail-table tr td:nth-child(odd) {
  width: 100px;
  text-align: right;
}
.g-detail-table tr td:nth-child(2) {
  width: 300px;
}
.g-detail-table-3 {
  width: 100%;
  border-collapse: collapse;
}
.g-detail-table-3 tr td {
  padding: 5px 10px;
  vertical-align: top;
}
.g-detail-table-3 tr td:nth-child(odd) {
  text-align: center;
  background-color: #f0f2f5;
  width: 100px;
}
.g-detail-table-3 tr td:nth-child(even) {
  min-width: 100px;
}
.g-btn-right-wrap {
  margin-top: 10px;
  text-align: right;
}
.g-clear-float {
  zoom: 1;
}
.g-clear-float:after {
  display: block;
  clear: both;
  content: '';
  visibility: hidden;
  height: 0;
}
.g-blue {
  color: #1b65b9 !important;
}
.g-red {
  color: #f56c6c !important;
}
.g-green {
  color: #67c23a !important;
}
.g-orange {
  color: #e6a23c !important;
}
.g-trend-wrap {
  position: absolute;
  top: 48px;
  left: 10px;
  right: 10px;
  bottom: 10px;
}
.g-color-1 {
  background-color: #1b65b9;
}
.g-color-2 {
  background-color: #4ac06d;
}
.g-color-3 {
  background-color: #f3af49;
}
.g-color-4 {
  background-color: #f56c6c;
}
.g-color-5 {
  background-color: #a16fe6;
}
.g-color-6 {
  background-color: #3ca0f6;
}
.g-color-7 {
  background-color: #7dd9b9;
}
.g-color-8 {
  background-color: #5ba934;
}
.g-box-list .g-box {
  float: left;
  border: 1px solid #cecece;
  margin-bottom: 10px;
  width: 16.7%;
  min-width: 150px;
  padding: 10px 0;
  max-height: 75px;
  margin-left: -1px;
  box-sizing: border-box;
}
.g-box-list .g-box:nth-child(6n-5) {
  margin-left: 0;
}
.g-box-list .g-box .left {
  float: left;
  margin-left: 15px;
}
.g-box-list .g-box .left p {
  text-align: center;
  font-size: 12px;
  color: #606266;
  white-space: nowrap;
}
.g-box-list .g-box .left p:nth-child(3) span:nth-child(1) {
  font-size: 16px;
  font-weight: bold;
}
.g-box-list .g-box .right {
  float: left;
  margin-left: 15px;
}
.g-box-list .g-box .right p {
  font-size: 0;
  white-space: nowrap;
  line-height: 1.5;
}
.g-box-list .g-box .right p i,
.g-box-list .g-box .right p span {
  display: inline-block;
  vertical-align: middle;
}
.g-box-list .g-box .right p i {
  width: 12px;
  height: 12px;
}
.g-box-list .g-box .right p span {
  margin-left: 2px;
  font-size: 12px;
  color: #606266;
}
.g-box-list .g-box-desc {
  border: 1px solid #cecece;
  float: left;
  width: 33.4%;
  min-width: 300px;
  padding: 10px 0;
  max-height: 75px;
  margin-bottom: 10px;
  box-sizing: border-box;
}
.g-box-list .g-box-desc .left {
  float: left;
  margin-left: 15px;
  font-size: 0;
  white-space: nowrap;
  line-height: 1.5;
}
.g-box-list .g-box-desc .left i,
.g-box-list .g-box-desc .left span {
  display: inline-block;
  vertical-align: middle;
}
.g-box-list .g-box-desc .left i {
  width: 12px;
  height: 12px;
}
.g-box-list .g-box-desc .left span {
  margin-left: 2px;
  font-size: 12px;
  color: #606266;
}
.g-box-list .g-box-desc .right {
  float: left;
  line-height: 1.5;
  font-size: 12px;
  margin-left: 60px;
}
.g-box-list .g-box-desc .right span:nth-child(2) {
  margin-left: 5px;
  font-weight: bold;
}
.g-chart-wrap {
  height: 340px;
  margin: 10px 0;
}
.g-chart-wrap .left,
.g-chart-wrap .right {
  height: 100%;
  border: 1px solid #cecece;
}
.g-chart-wrap .left {
  float: left;
  width: 65%;
}
.g-chart-wrap .right {
  float: right;
  width: 34%;
}
.g-code-list {
  font-size: 0;
  padding: 10px 0;
}
.g-code-list .father {
  font-size: 16px;
  color: #303133;
  font-weight: bold;
  text-align: center;
  line-height: 2;
}
.g-code-list .g-code-item {
  display: inline-block;
  font-size: 0;
  padding: 5px 0;
  min-width: 220px;
  box-sizing: border-box;
}
.g-code-list .g-code-item.big {
  min-width: 290px;
}
.g-code-list .g-code-item.bg {
  background-color: #f0f2f5;
}
.g-code-list .g-code-item span {
  font-size: 14px;
  color: #606266;
  text-align: center;
  display: inline-block;
  vertical-align: middle;
}
.g-code-list .g-code-item span:nth-child(1) {
  width: 50px;
}
.g-code-list .g-code-item span:nth-child(2) {
  width: 170px;
  word-break: break-all;
}
.g-code-list .g-code-item span.count {
  color: #f56c6c;
  margin-left: 2px;
  width: 68px;
}
.g-code-list .g-code-item.item-title {
  background-color: #f0f2f5;
}
.g-code-list .g-code-item.item-title span {
  color: #303133;
}
.g-code-list .g-code-item.item-title.big span:nth-child(2) {
  width: 240px;
}
.g-container.g-zhaocai-special {
  background-color: #f1f2f6;
}
.g-zhaocai-special {
  font-family: Arial, Helvetica Neue, Helvetica, Microsoft YaHei, Hiragino Sans GB, PingFang SC, sans-serif;
}
.g-zhaocai-special .g-title {
  padding: 0 0 0 10px;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 16px;
}
.g-zhaocai-special .g-title::before {
  top: 0;
  width: 6px;
  height: 16px;
}
.g-zhaocai-special .g-search-wrap {
  padding-top: 0;
}
.g-zhaocai-special .g-first {
  padding: 16px 16px 10px 16px;
  box-sizing: border-box;
}
.g-zhaocai-special .g-second {
  padding: 16px 16px 10px 16px;
  box-sizing: border-box;
  background-color: #f1f2f6;
}
.g-zhaocai-special .g-search-box {
  background-color: #fff;
  padding: 10px;
  margin-bottom: 12px;
}
.g-zhaocai-special .el-form-item__label {
  color: #303133;
}
.g-zhaocai-special .el-input__inner {
  border-radius: 4px;
}
.g-zhaocai-special .el-input__inner::-webkit-input-placeholder {
  color: #909399;
}
.g-zhaocai-special .el-input__inner::-moz-placeholder {
  color: #909399;
}
.g-zhaocai-special .el-input__inner::-ms-input-placeholder {
  color: #909399;
}
.g-zhaocai-special .el-input__inner::placeholder {
  color: #909399;
}
.g-zhaocai-special .el-button--small {
  padding: 9px 15px;
  font-size: 12px;
  border-radius: 3px;
}
.g-zhaocai-special .el-form--inline .el-form-item__content {
  width: 130px;
}
.g-zhaocai-special .g-border-wrap {
  border: none;
  background-color: #fff;
  left: 16px;
  right: 16px;
  bottom: 10px;
}
.g-zhaocai-special .el-table .cell {
  color: #303133;
}
.g-zhaocai-special .g-border-table-wrap {
  bottom: 94px;
}
.g-zhaocai-special .g-border-btn-wrap {
  bottom: 54px;
}
.g-zhaocai-special .g-page-wrap {
  left: 26px;
  right: 26px;
  bottom: 20px;
}
.g-zhaocai-special .g-dialog .el-dialog__header {
  padding: 16px;
}
.g-zhaocai-special .g-dialog .el-dialog__header .el-dialog__title {
  font-weight: 700;
  line-height: 1;
  overflow: hidden;
}
.g-zhaocai-special .g-dialog .el-dialog__header .el-dialog__title::before {
  width: 6px;
  top: 0;
}
.g-zhaocai-special .el-table--border::after,
.g-zhaocai-special .el-table--group::after,
.g-zhaocai-special .el-table::before {
  background-color: #f2f6fc;
}
.g-zhaocai-special .el-table--border,
.g-zhaocai-special .el-table--group {
  border-color: #f2f6fc;
}
.g-zhaocai-special .g-dialog .el-dialog__body {
  padding: 10px 16px 0 16px;
}
.g-zhaocai-special .g-btn-right-wrap {
  margin-top: 0;
  line-height: 50px;
}
.g-zhaocai-special .g-table-wrap {
  background-color: #fff;
  left: 16px;
  right: 16px;
  bottom: 10px;
  padding: 0 10px;
}
.g-zhaocai-special .g-table-wrap .g-title {
  line-height: 50px;
  margin-bottom: 10px;
}
.g-zhaocai-special .g-table-wrap .g-title::before {
  top: 16px;
}
.g-zhaocai-special .g-table-wrap .table-set {
  height: calc(100% - 114px);
}
.g-zhaocai-special .g-tabel-empty {
  line-height: 1;
}
.g-zhaocai-special .g-tabel-empty .empty-img {
  font-size: 0;
  height: 144px;
}
.g-zhaocai-special .g-tabel-empty .empty-img img {
  width: 144px;
}
.g-zhaocai-special .g-tabel-empty p {
  margin-top: 16px;
  line-height: 24px;
  font-size: 14px;
  color: #606266;
}
.g-zhaocai-special .el-table__body tr.hover-row.current-row>td,
.g-zhaocai-special .el-table__body tr.hover-row.el-table__row--striped.current-row>td,
.g-zhaocai-special .el-table__body tr.hover-row.el-table__row--striped>td,
.g-zhaocai-special .el-table__body tr.hover-row>td {
  background-color: #f5f7fa;
}
.g-zhaocai-special .el-table th {
  height: 67px;
}
.g-zhaocai-special .el-table th,
.g-zhaocai-special .el-table td {
  border-color: #cecece #cecece rgba(206,206,206,0.3);
  border-top-color: #cecece;
  border-right-color: #cecece;
  border-bottom-color: rgba(206,206,206,0.3);
  border-left-color: #cecece;
  right: 1px;
}
/*# sourceMappingURL=src/assets/css/comm.css.map */