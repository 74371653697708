.g-container {
  height: 100%;
  color: #606266;
  position: relative;
  background-color: #ffffff;

  .g-first {
    height: 100%;
    padding: 0 10px;
    position: relative;
  }

  .g-second {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #ffffff;
    z-index: 10;
    padding: 0 10px;
  }
}

.g-search-wrap {
  padding: 10px 150px 0 0;
  position: relative;

  .g-search-btn {
    position: absolute;
    right: 0;
    // bottom: 14px;
    top 15px
  }
}

.g-title {
  position: relative;
  padding: 15px 0 15px 13px;
  line-height: 1;
  font-size: 18px;
  font-weight: bold;
  color: #303133;

  &:before {
    content: '';
    position: absolute;
    top: 17px;
    height: 18px;
    width: 4px;
    background-color: #1b65b9;
    left: 0;
  }
}

.g-desc-wrap {
  font-size: 0;
  border: 1px solid #cecece;
  border-radius: 2px;
  padding: 10px 15px;

  .g-desc-item {
    display: inline-block;
    font-size: 14px;
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }

    label {
      font-weight: bold;
    }

    span {
      margin-left: 5px;
      color: #1b65b9;
    }
  }
}

.g-table-wrap {
  position: absolute;
  left: 10px;
  right: 10px;
  bottom: 44px;
}

.g-tabel-btn {
  font-size: 14px;
  color: #1B65B9;
  cursor: pointer;
  margin-right: 5px;

  &:hover {
    color: #4581C6;
  }

  &:last-child {
    margin-right: 0;
  }
}

.g-border-wrap {
  border: 1px solid #cecece;
  padding: 10px;
  border-radius: 2px;
  position: absolute;
  left: 10px;
  right: 10px;
  bottom: 44px;

  .g-border-title {
    font-size: 16px;
    font-weight: bold;
    color: #303133;
  }

  .g-border-table-wrap {
    position: absolute;
    left: 10px;
    right: 10px;
    bottom: 50px;
  }

  .g-border-btn-wrap {
    position: absolute;
    left: 10px;
    right: 10px;
    bottom: 10px;
  }
}

.g-page-wrap {
  padding: 10px 0;
  position: absolute;
  bottom: 0;
  left: 10px;
  right: 10px;
}

.g-detail-table {
  width: 100%;
  border-collapse: collapse;

  tr {
    &:nth-child(odd) {
      td {
        background-color: #f0f2f5;
      }
    }

    td {
      padding: 10px 15px 10px 5px;
      vertical-align: top;

      &:nth-child(odd) {
        width: 100px;
        text-align: right;
      }

      &:nth-child(2) {
        width: 300px;
      }
    }
  }
}

.g-detail-table-3 {
  width: 100%;
  border-collapse: collapse;

  tr {
    td {
      padding: 5px 10px;
      vertical-align: top;

      &:nth-child(odd) {
        text-align: center;
        background-color: #f0f2f5;
        width: 100px;
      }

      &:nth-child(even) {
        min-width: 100px;
      }
    }
  }
}

.g-btn-right-wrap {
  margin-top: 10px;
  text-align: right;
}

.g-clear-float {
  zoom: 1;

  &:after {
    display: block;
    clear: both;
    content: '';
    visibility: hidden;
    height: 0;
  }
}

.g-blue {
  color: #1B65B9 !important;
}

.g-red {
  color: #f56c6c !important;
}

.g-green {
  color: #67c23a !important;
}

.g-orange {
  color: #e6a23c !important;
}

.g-trend-wrap {
  position: absolute;
  top: 48px;
  left: 10px;
  right: 10px;
  bottom: 10px;
}

.g-color-1 {
  background-color: #1b65b9;
}

.g-color-2 {
  background-color: #4ac06d;
}

.g-color-3 {
  background-color: #f3af49;
}

.g-color-4 {
  background-color: #f56c6c;
}

.g-color-5 {
  background-color: #a16fe6;
}

.g-color-6 {
  background-color: #3ca0f6;
}

.g-color-7 {
  background-color: #7dd9b9;
}

.g-color-8 {
  background-color: #5ba934;
}

.g-box-list {
  .g-box {
    float: left;
    border: 1px solid #cecece;
    margin-bottom: 10px;
    width: 16.7%;
    min-width: 150px;
    padding: 10px 0;
    max-height: 75px;
    margin-left: -1px;
    box-sizing: border-box;

    &:nth-child(6n-5) {
      margin-left: 0;
    }

    .left {
      float: left;
      margin-left: 15px;

      p {
        text-align: center;
        font-size: 12px;
        color: #606266;
        white-space: nowrap;

        &:nth-child(3) {
          span {
            &:nth-child(1) {
              font-size: 16px;
              font-weight: bold;
            }
          }
        }
      }
    }

    .right {
      float: left;
      margin-left: 15px;

      p {
        font-size: 0;
        white-space: nowrap;
        line-height: 1.5;

        i, span {
          display: inline-block;
          vertical-align: middle;
        }

        i {
          width: 12px;
          height: 12px;
        }

        span {
          margin-left: 2px;
          font-size: 12px;
          color: #606266;
        }
      }
    }
  }

  .g-box-desc {
    border: 1px solid #cecece;
    float: left;
    width: 33.4%;
    min-width: 300px;
    padding: 10px 0;
    max-height: 75px;
    margin-bottom: 10px;
    box-sizing: border-box;

    .left {
      float: left;
      margin-left: 15px;
      font-size: 0;
      white-space: nowrap;
      line-height: 1.5;

      i, span {
        display: inline-block;
        vertical-align: middle;
      }

      i {
        width: 12px;
        height: 12px;
      }

      span {
        margin-left: 2px;
        font-size: 12px;
        color: #606266;
      }
    }

    .right {
      float: left;
      line-height: 1.5;
      font-size: 12px;
      margin-left: 60px;

      span {
        &:nth-child(2) {
          margin-left: 5px;
          font-weight: bold;
        }
      }
    }
  }
}

.g-chart-wrap {
  height: 340px;
  margin: 10px 0;

  .left, .right {
    height: 100%;
    border: 1px solid #cecece;
  }

  .left {
    float: left;
    width: 65%;
  }

  .right {
    float: right;
    width: 34%;
  }
}

.g-code-list {
  font-size: 0;
  padding: 10px 0;

  .father {
    font-size: 16px;
    color: #303133;
    font-weight: bold;
    text-align: center;
    line-height: 2;
  }

  .g-code-item {
    display: inline-block;
    font-size: 0;
    padding: 5px 0;
    min-width: 220px;
    box-sizing: border-box;

    &.big {
      min-width: 290px;
    }

    &.bg {
      background-color: #f0f2f5;
    }

    span {
      font-size: 14px;
      color: #606266;
      text-align: center;
      display: inline-block;
      vertical-align: middle;

      &:nth-child(1) {
        width: 50px;
      }

      &:nth-child(2) {
        width: 170px;
        word-break: break-all;
      }

      &.count {
        color: #f56c6c;
        margin-left: 2px;
        width: 68px;
      }
    }

    &.item-title {
      background-color: #f0f2f5;

      span {
        color: #303133;
      }

      &.big {
        span {
          &:nth-child(2) {
            width: 240px;
          }
        }
      }
    }
  }
}

// 以下为招采页面样式
.g-container.g-zhaocai-special {
  background-color: #f1f2f6;
}
.g-zhaocai-special {
  font-family: Arial, Helvetica Neue, Helvetica, Microsoft YaHei, Hiragino Sans GB, PingFang SC, sans-serif;
  .g-title {
    padding: 0 0 0 10px;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 16px;

    &::before {
      top: 0;
      width: 6px;
      height: 16px;
    }
  }

  .g-search-wrap {
    padding-top: 0;
  }

  .g-first {
    padding: 16px 16px 10px 16px;
    box-sizing: border-box;
  }

  .g-second {
    padding: 16px 16px 10px 16px;
    box-sizing: border-box;
    background-color: #f1f2f6;
  }
  .g-search-box {
      background-color: #ffffff;
      padding: 10px;
      margin-bottom: 12px;
    }

    .el-form-item__label {
      color: #303133;
    }

    .el-input__inner {
      border-radius: 4px;
    }

    .el-input__inner::-webkit-input-placeholder {
      color: #909399;
    }

    .el-input__inner::-moz-placeholder {
      color: #909399;
    }

    .el-input__inner::-ms-input-placeholder {
      color: #909399;
    }

    .el-input__inner::placeholder {
      color: #909399;
    }

    .el-button--small {
      padding: 9px 15px;
      font-size: 12px;
      border-radius: 3px;
    }
    .el-form--inline .el-form-item__content{
      width 130px  
    }

    .g-border-wrap {
      border: none;
      background-color: #ffffff;
      left: 16px;
      right: 16px;
      bottom 10px
    }
    .el-table .cell {
      color #303133  
    }
    .g-border-table-wrap {
      bottom 94px  
    }
    .g-border-btn-wrap {
      bottom 54px  
    }
    .g-page-wrap {
      left 26px
      right 26px  
      bottom 20px
    }

  .g-dialog .el-dialog__header {
    padding 16px  
    .el-dialog__title {
      font-weight: 700
      line-height 1
      overflow: hidden
      &::before {
        width 6px
        top 0  
      }  
    }
  }
  .el-table--border::after, .el-table--group::after, .el-table::before {
    background-color #f2f6fc  
  }
  .el-table--border, .el-table--group {
    border-color #f2f6fc   
  }
  .g-dialog .el-dialog__body {
    padding 10px 16px 0 16px  
  }
  .g-btn-right-wrap {
    margin-top 0
    line-height 50px  
  }
  .g-table-wrap {
    background-color #ffffff  
    left 16px
    right 16px
    bottom 10px
    padding 0 10px
    .g-title {
      line-height 50px  
      margin-bottom 10px
      &::before {
        top 16px  
      }
    }
    .table-set{
      height calc(100% - 114px)  
    }
  }
  .g-tabel-empty {
    line-height 1
    .empty-img {
      font-size 0
      height 144px
      img {
        width 144px
      }  
    }  
    p {
      margin-top 16px
      line-height 24px
      font-size 14px
      color #606266
    }
  }
  .el-table__body tr.hover-row.current-row>td, .el-table__body tr.hover-row.el-table__row--striped.current-row>td, .el-table__body tr.hover-row.el-table__row--striped>td, .el-table__body tr.hover-row>td {
    background-color #f5f7fa  
  }
  .el-table th {
    height 67px 
  }
  .el-table th, .el-table td {
    border-color #cecece #cecece hsla(0,0%,80.8%,.3)
    border-top-color rgb(206,206,206)
    border-right-color rgb(206,206,206)
    border-bottom-color rgba(206,206,206,.3)
    border-left-color rgb(206,206,206)
    right 1px
  }
}