html,
body {
  height: 100%;
  overflow: hidden;
  font-family: -apple-system, SF UI Text, PingFang SC, Hiragino Sans GB, Microsoft YaHei, WenQuanYi Micro Hei, "Helvetica Neue", Helvetica,;
}
* {
  margin: 0;
  padding: 0;
}
ul,
li {
  list-style: none;
}
/*# sourceMappingURL=src/assets/css/reset.css.map */